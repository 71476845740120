import React, { useState, useEffect } from 'react';
import TitleDescriptionGeneral from "../../TitleDescritptionGeneral"

const titleDescriptionAbout = {
    title: "About Flipbase",
    descriptionFirst: "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology.",
    descriptionSecond: "At Flipbase we always put customers first,  we keep our technology as simple as we can, we do things differently to be able to innovate and that means you have to dare to make mistakes."
}

/* Title Description About component from the page about us */

class TitleDescriptionAbout extends React.Component {
    
    render() {
        return (
          <TitleDescriptionGeneral data={titleDescriptionAbout} xlSizeTitle={11} />
        );
    }
  }


export default TitleDescriptionAbout;