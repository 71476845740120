import React, { useState, useEffect } from "react"
import styled from "styled-components"

import { Col, Row, Modal } from "antd"
import { device, colors } from "../../../styles/constants"
import DotsGroup from "../../DecorationElements/DotsGroup"
import ModalAboutComponent from "../ModalAboutComponent"

/* Card Row from the page about us */

const CardsRowData = [
  {
    title: "Commercial",
    sub_type: "About",
    description:
      "This department focuses on getting our solution to the attention of the right target audience. This includes sales, marketing and partner management.",
    videoThumb: "/thumbnail_commercial_department.jpg",
    modalData: {
      title: "Commercial Team",
      descriptionFirst:
        "The commercial team consists of marketing, sales and partner specialists. Their main goal is to get the right people acquainted with our technology, boost our brand awareness and eventually acquire more customers. ",
      descriptionSecond:
        "The different specialists work closely together to create the right content and use the right tools to reach our target audience in the best way possible.",
      videoSrc:
        "https://app.flipbase.com/embed/0465e34c-d223-4b66-bc0c-5654130c4594",
      team: [
        {
          name: "Bram Tierie",
          position: "Co-founder, CCO",
          img: "/Bram.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/bram-tierie-b877551b/",
            },
          ],
        },
        {
          name: "Stephan Kroon",
          position: "Growth Expert",
          img: "/Stephan.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/stephan-kroon-4446ba181/",
            },
          ],
        },
        {
          name: "Sonan Le",
          position: "Growth Specialist",
          img: "/Sonan.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/sonanle/",
            },
          ],
        },
        {
          name: "Joppe Bakker",
          position: "Growth Specialist",
          img: "/Joppe.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/joppe-bakker/",
            },
          ],
        },
        {
          name: "Jamil Florusse",
          position: "Online Marketing Specialist",
          img: "/Jamil.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/jamil-florusse/",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Success",
    sub_type: "About",
    description:
      "This department focuses on putting customers first and making sure that they use our solution in the best way possible to achieve their desired results.",
    videoThumb: "/thumbnail_success_department.jpg",
    modalData: {
      title: "Success Team",
      descriptionFirst:
        "The Success team consists of customer success and support specialists. Their main goal is to make customers happy by achieving the desired results with our technology.",
      descriptionSecond:
        "The different specialists work closely together with the commercial team to answer questions and resolve issues as soon as possible and to work closely with our customers so we can help them in the best way possible.",
      videoSrc:
        "https://app.flipbase.com/embed/397a783f-afb6-4a7a-80dc-2f6dd70bd34e",
      team: [
        {
          name: "Ellyn Asmara",
          position: "Customer Success Specialist",
          img: "/Ellyn.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/ellynasmara/",
            },
          ],
        },
        {
          name: "Deborah Wolleswinkel",
          position: "Customer Success Specialist",
          img: "/Deborah.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon:
                "https://www.linkedin.com/in/deborah-wolleswinkel-a9b561164/",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Product",
    sub_type: "About",
    description:
      "They know what (potential) customers are looking for so we are able to build the right things at the right time.",
    videoThumb: "/thumbnail_product_department.jpg",
    modalData: {
      title: "Product Team",
      descriptionFirst:
        "The product team consists of product, user experience (UX) and user interface (UI) professionals. Their main goal is to translate the needs from (potential) customers into feature and/or product ideas and prioritize these in our planning.",
      descriptionSecond:
        "The different specialists work closely together with the success team to be able to build the right things at the right time.",
      videoSrc:
        "https://app.flipbase.com/embed/5120293b-583b-4534-90dc-0f44cd51705e",
      team: [
        {
          name: "Joris Knulst",
          position: "Product Manager",
          img: "/Joris.jpg",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/joris-knulst-3920a5a5/",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Development",
    description:
      "This department focuses on building our software. They know best how to translate the needs of (potential) customers to cutting edge technology.",
    videoThumb: "/thumbnail_development_department.jpg",
    modalData: {
      title: "Development Team",
      descriptionFirst:
        "The development team consists of devops, frontend and backend development specialists. Their main goal is to create cutting edge technology that is innovative and fits the needs of our (potential) customers in the best way possible.",
      descriptionSecond:
        "The different specialists work closely together with the product team to build the best technology for our target audience.",
      videoSrc:
        "https://app.flipbase.com/embed/adde5037-f4a4-41dc-b798-0b13bbf462dc",
      team: [
        {
          name: "Ron Jansen",
          position: "Co-founder, CTO",
          img: "/Ron.png",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/ronxjansen/",
            },
          ],
        },
        {
          name: "Aleff Souza",
          position: "Senior Developer",
          img: "/Aleff.jpeg",
          linkedin: [
            {
              icon: "/ln_light_contact.svg",
              colorIcon: "/ln_color_contact.svg",
              linkIcon: "https://www.linkedin.com/in/aleff-souza-560a25b1/",
            },
          ],
        },
      ],
    },
  },
]

class CardsRowAbout extends React.Component {
  render() {
    return (
      <ContentWrapper>
        <Title>Key departments</Title>
        <StyledRow gutter={24} justify="space-between">
          <DotsGroup quantity={55} left="-8%" top="74%" type="big" />

          {CardsRowData.map(card => {
            return <ModalAboutComponent data={card} />
          })}
        </StyledRow>
      </ContentWrapper>
    )
  }
}

const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
`

const Title = styled.h3`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
  color: ${colors.dark};
  @media ${device.laptopL} {
    font-size: 34px;
    line-height: 38px;
    margin-bottom: 12px;
  }
  @media ${device.tablet} {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 31px;
  }
  @media ${device.mobileL} {
    font-size: 26px;
    line-height: 29px;
    margin-bottom: 21px;
  }
`

const StyledRow = styled(Row)`
  width: 100%;
`

export default CardsRowAbout
