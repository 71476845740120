import React, { useState, useEffect } from 'react';
import styled from "styled-components"
import FlipbaseIcons from "../../DecorationElements/FlipbaseIcons"

import { Col } from 'antd'
import { device, colors } from "../../../styles/constants";

/* Core Values from the page about us */ 

const CoreValuesData = [
    {
        title:"Keep it stupidly simple",
        icon:"keep-simple",
    },
    {
        title:"Dare to make mistakes",
        icon:"mistakes",
    },
    {
        title:"Do things differently",
        icon:"things"
    },
    {
        title:"Always put customers first",
        icon:"support"
    }
]



class CoreValues extends React.Component {
    
    render() {
        return (
          <ContentWrapper xl={11} lg={12} md={24} offset={1} >
              <Title>Core Values</Title>
              <WrapperValues>
                {CoreValuesData.map(value => {
                    return (
                        <StyledCol xl={11} lg={11} md={12} sm={12} xs={24}>
                            <FlipbaseIcons 
                                type={value.icon} 
                                bgColor="#ffffff" 
                                radiusIcon="3px" 
                                shadow="0px 8px 15px rgba(0, 0, 0, 0.08)"
                            />
                            <TitleValue>{value.title}</TitleValue>
                        </StyledCol>
                       
                    )
                })}
              </WrapperValues>
          </ContentWrapper>
        );
    }
}

const ContentWrapper = styled(Col)`
    @media (max-width:994px) {
        margin-left:0 !important;
    }
`

const Title = styled.h3`
    font-family:FreightSans;
    font-weight:600;
    font-size:42px;
    line-height:50px;
    color:${colors.dark};
    @media ${device.laptopL} {
        font-size:34px;
        line-height:38px;
    }
    @media ${device.tablet} {
        font-size:30px;
        line-height:34px;
        margin-bottom:31px;
    }
    @media ${device.mobileL} {
        font-size:26px;
        line-height:29px;
        margin-bottom:21px;
    }
`

const StyledCol = styled(Col)`
    display:flex !important;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    margin-bottom:29px;
    @media ${device.laptopL} {
        margin-bottom:12px;
    }
`

const WrapperValues = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
`

const TitleValue = styled.h5`
    font-family:FreightSans;
    font-weight:600;
    font-size:18px;
    line-height:22px;
    color:${colors.dark};
    margin-left:24px;
`

export default CoreValues;