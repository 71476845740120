import React from "react"
import { GatsbyLocation } from "local-types"
import styled from "styled-components"
import { useSiteMetadata } from "../hooks"
import SEO from "../components/Seo/Seo"

import SectionLayout from "../components/WrapperSections/SectionLayout"
import Layout from "../components/Layout"

import TitleDescriptionAbout from "../components/AboutUsComponents/TitleDescriptionAbout"
import CoreValues from "../components/AboutUsComponents/CoreValues"
import CardsRowAbout from "../components/AboutUsComponents/CardsRowAbout"
import FooterContact from "../components/ContactComponents/FooterContact"

import { device } from "../styles/constants"

const aboutMetadata = {
  Title: "About Us - Flipbase",
  Keywords: ["Flipbase About Us", "About Flipbase"],
  Description:
    "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology. Read more about Flipbase.",
  Image: "/seo-image-general.png",
}

interface AboutUsProps {
  location: GatsbyLocation
  preivousPath?: string
  nextPath?: string
}

const AboutUs: React.FunctionComponent<AboutUsProps> = ({
  location,
  nextPath = "/Careers",
}): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata()

  return (
    <Layout location={location} title={siteTitle} nextPath={nextPath}>
      <SEO
        title={aboutMetadata.Title}
        description={aboutMetadata.Description}
        image={aboutMetadata.Image}
        keywords={aboutMetadata.Keywords}
      />
      <SectionLayout>
        <ContentWrapper>
          <TitleDescriptionAbout />
          <CoreValues />
          <CardsRowAbout />
        </ContentWrapper>
      </SectionLayout>
      <FooterContact paddingLaptop="20px 20px 20px 120px;" elPosition="relative"  />
    </Layout>
  )
}

const ContentWrapper = styled.div`
  padding-top: 96px;
  margin-bottom:24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${device.laptopL} {
    padding-top: 32px;
  }
  @media ${device.tablet} {
    margin: 0 30px;
  }
  @media ${device.mobileL} {
    margin: 0 16px 32px;
  }
`

export default AboutUs
