import React from "react"

import styled from "styled-components"

import DotsGroup from "../../DecorationElements/DotsGroup"
import TitleDescriptionGeneral from "../../TitleDescritptionGeneral"
import FlipbaseCard from "../../FlipbaseCard"
import IFrameVideo from "../../IFrameVideo"
import { Col, Row, Modal } from "antd"
import { device, colors } from "../../../styles/constants"
import IconsContact from "../../DecorationElements/IconsContact"

class ModalAboutComponent extends React.Component {
  state = { visible: false }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = e => {
    this.setState({
      visible: false,
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    })
  }

  render() {
    const titleDesData = {
      title: this.props.data.modalData.title,
      descriptionFirst: this.props.data.modalData.descriptionFirst,
      descriptionSecond: this.props.data.modalData.descriptionSecond,
    }

    return (
      <>
        <StyledCol xl={6} lg={6} md={12} sm={12} xs={24}>
          <CardWrapper onClick={this.showModal}>
            <FlipbaseCard
              type="Thumbnail"
              maxWidth="347px"
              data={this.props.data}
            />
          </CardWrapper>
          <StyledModal
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            centered
            footer={[]}
            width={960}
          >
            <ModalBody>
              <StyledRowBorder gutter={48}>
                <TitleDescriptionGeneral data={titleDesData} xlSize={13} />
                <Col xl={11} lg={11} md={24} sm={24} xs={24}>
                  <WrapperFrame>
                    <IFrameVideo
                      videoSrc={this.props.data.modalData.videoSrc}
                    />
                  </WrapperFrame>
                </Col>
              </StyledRowBorder>
              <StyledRow>
                <TitleTeam>The Team </TitleTeam>
                <StyledRow gutter={32}>
                  {this.props.data.modalData.team.map((member, index) => {
                    return (
                      <Col xl={6} lg={6} md={10} sm={24} xs={24}>
                        <DotsGroup
                          quantity={50}
                          left="-6%"
                          top="6%"
                          type="small"
                        />
                        <ImageTitle src={member.img} alt={member.name} />
                        <ConnectOn>
                          {member.linkedin !== undefined && (
                            <IconsContact icons={member.linkedin} />
                          )}
                        </ConnectOn>
                        <MemberName>{member.name}</MemberName>
                        <MemberPosition>{member.position}</MemberPosition>
                      </Col>
                    )
                  })}
                </StyledRow>
              </StyledRow>
            </ModalBody>
          </StyledModal>
        </StyledCol>
      </>
    )
  }
}

const StyledCol = styled(Col)`
  margin-bottom: 8px;
`

const StyledRow = styled(Row)`
  width: 100%;
  padding: 40px;
  justify-content: flex-start !important;
  @media ${device.laptopL} {
    padding: 0 32px 16px;
    justify-content: flex-start;
  }
  @media ${device.tablet} {
    justify-content: space-between;
  }
  @media ${device.mobileL} {
    justify-content: center;
  }
  img  {
    display:block !important;
  }
`

const StyledRowBorder = styled(Row)`
  width: 100%;
  box-sizing: border-box;
  padding: 40px;

  @media ${device.laptopL} {
    padding: 12px 24px;
  }
`

// max-height: 90% !important;
const StyledModal = styled(Modal)`
  .ant-modal-close-x {
    color: #a62651;
  }
  padding-bottom: 0px !important;
  @media ${device.laptopL} {
    max-width: calc(100vw - 32px) !important;
  }
  @media ${device.mobileL} {
    padding-bottom: 96px !important;
  }
  .ant-modal-body {
    width: 100%;
    height: auto;
    max-height: 620px !important;
    padding: 8px !important;
    overflow-y: scroll;

    padding: 0 !important @media (max-width: 575px) {
      padding: 40px 16px 0;
    }
  }
  .ant-modal-footer {
    padding: 0;
  }
`
const ModalBody = styled.div`
  padding: 0;
`

const CardWrapper = styled.div`
  margin-bottom: 8px !important;
`

const TitleTeam = styled.h4`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: ${colors.dark};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding-top: 24px;
  margin-bottom: 0;
`

const ImageTitle = styled.img`
  
  width: 100%;
  position: relative;
  z-index: 10;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  margin: 16px 0;
  object-fit: cover;
  max-height: 129px;
`

const MemberName = styled.h5`
  font-family: FreightSans;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  color: ${colors.dark};
  margin-bottom: 8px;
`

const MemberPosition = styled.span`
  font-family: FreightSans;
  font-size: 14px;
  line-height: 20px;
  margin: 0 !important;
  color: rgba(35, 29, 48, 0.6);
`

const ConnectOn = styled.div`
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 108px;
  z-index: 10;
`
const WrapperFrame = styled.div`
  margin-top: 24px;
`

export default ModalAboutComponent
